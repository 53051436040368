// Remember to update the C# version of this enum to.
export enum JourneyLogType {
    Session = 1,
    PageVisit = 2,
    APIError = 3,
    Error = 4,
    SaveAndExit = 5,
    SaveAndExitError = 6,
    Published = 7,
    ClientSideError = 8
}
