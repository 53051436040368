import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JourneyMilestone } from '../entities/journey-milestone';
import { JourneyService } from './journey.service';
import { MilestoneStatuses } from '../enums/milestone-status';
import { NavigationService } from './navigation.service';
import { Pages } from '../enums/page';
import { StepState } from '../enums/step-state';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyMilestoneService {

  constructor(
    private http: HttpClient,
    private journeyService: JourneyService,
    private navigationService: NavigationService
  ) { }

  journeyMilestones = signal<JourneyMilestone[]>([]);

  getJourneyMilestones(): Observable<JourneyMilestone[]> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    return this.http.get<JourneyMilestone[]>(`/api/journeymilestones/${journeyID}/latest`);
  }

  getMilestoneForPage(page: Pages): Observable<JourneyMilestone | null> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    const pageId = page.valueOf();
    return this.http.get<JourneyMilestone | null>(`/api/journeymilestones/GetLatestMilestoneForPage/${journeyID}/${pageId}/latest`);
  }
  saveJourneyMilestones(journeyMilestones: JourneyMilestone[]): Observable<JourneyMilestone[]> {
    return this.http.post<JourneyMilestone[]>("/api/journeymilestones/", journeyMilestones);
  }
  async addJourneyMilestone(journeyMilestone: JourneyMilestone): Promise<void> {
    this.journeyMilestones.update(x => [...x, journeyMilestone]);
    await firstValueFrom(this.saveJourneyMilestones([journeyMilestone]));
  }

  async refresh(): Promise<void> {
    this.journeyMilestones.set(await firstValueFrom(this.getJourneyMilestones()));

    this.updateSteps();
  }

  createJourneyExcludedAdviceArea(pageID: number, statusID: number): JourneyMilestone {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      pageID,
      statusID
    };
  }

  async updateJourneyMilestone(pageID: number, statusID: number): Promise<void> {
    let journeyMilestone = this.journeyMilestones().find(x => x.pageID === pageID);

    if (!journeyMilestone) {
      journeyMilestone = this.createJourneyExcludedAdviceArea(pageID, statusID);
    }

    this.journeyMilestones
      .update(x => [...x.filter(y => y.pageID !== journeyMilestone.pageID), journeyMilestone]);
    await firstValueFrom(this.saveJourneyMilestones([journeyMilestone]));
  }

  private updateSteps(): void {
    for (const journeyMilestone of this.journeyMilestones()) {
      const step = this.navigationService.steps.find(x => Number(x.page) === journeyMilestone.pageID);

      if (!step) continue;

      if (journeyMilestone.statusID === Number(MilestoneStatuses.Completed)) {
        step.stepState = StepState.Completed;
        step.leftCompleted = true;

        const nextStep = this.navigationService.steps.find(x => x.identifier === step.nextStepIdentifier);

        if (nextStep) {
          const nextMilestone = this.journeyMilestones().find(x => x.pageID === Number(nextStep.page));

          if (nextMilestone?.statusID === Number(MilestoneStatuses.Completed)) {
            step.rightCompleted = true;
          }
        }
      }
    }

    this.updateParentSteps();
  }

  private updateParentSteps(): void {
    for (const parentStep of this.navigationService.steps.filter(x=> !x.parentStepID)) {
      parentStep.stepState = (this.navigationService.steps.some(x => x.stepState !== StepState.Completed && x.parentStepID === parentStep.page)) ? StepState.Visited : StepState.Completed;
    }
  }
}