import { Observable, firstValueFrom } from 'rxjs';
import { AdviserService } from './adviser.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { JourneyLog } from '../entities/journey-log';
import { JourneyLogType } from '../enums/journey-log-type';
import { JourneyService } from './journey.service';
import { PdfSection } from '../entities/pdf-content';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { createGuid } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyLogsService {

  constructor(
    private http: HttpClient,
    private journeyService: JourneyService,
    private journeyClientService: JourneyClientService,
    private adviserService: AdviserService
  ) { }

  saveJourneyLog(journeyLogs: JourneyLog[]): Observable<JourneyLog[]> {
    return this.http.post<JourneyLog[]>("/api/journeylogs/", journeyLogs);
  }

  getJourneyLogs(journeyID: string, journeyLogTypeID: number): Observable<JourneyLog[] | null> {
    return this.http.get<JourneyLog[] | null>(`/api/journeylogs/${journeyID}/${journeyLogTypeID}`);
  }

  createJourneyLog(journeyLogTypeID: number, journeyID: string, message?: string, pageID?: number,
    arrived?: Date, navigated?: Date, journeyInstanceID?: string, journeyClientID?: string): JourneyLog {
    return {
      message,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      userNode: this.adviserService.getNonNullableLoggedInUser().node,
      arrived,
      navigated,
      journeyID,
      pageID,
      journeyLogTypeID,
      journeyLogID: createGuid(),
      journeyInstanceID,
      journeyClientID
    }
  }

  async createSaveAndExitLog(message: string): Promise<void> {
    const createDate = moment();
    await firstValueFrom(this.saveJourneyLog([
      this.createJourneyLog(
        JourneyLogType.SaveAndExit,
        this.journeyService.getNonNullableJourney().journeyID,
        `Save and exit, ${this.adviserService.getLoggedInUserName()}, ${createDate.format('dddd, MMMM Do YYYY, h:mm:ss a Z')} - ${message}`,
        void null,
        void null,
        createDate.toDate(),
        this.journeyService.getNonNullableJourney().journeyInstanceID,
        this.journeyClientService.getNonNullablePrimaryClientID()
      )]));
  }

  async createPublishedLog(message: string): Promise<void> {
    const createDate = moment();
    await firstValueFrom(this.saveJourneyLog([
      this.createJourneyLog(
        JourneyLogType.Published,
        this.journeyService.getNonNullableJourney().journeyID,
        `Published, ${this.adviserService.getLoggedInUserName()}, ${createDate.format('dddd, MMMM Do YYYY, h:mm:ss a Z')} - ${message}`,
        void null,
        void null,
        createDate.toDate(),
        this.journeyService.getNonNullableJourney().journeyInstanceID,
        this.journeyClientService.getNonNullablePrimaryClientID()
      )]));
  }

  async getPdfSections(): Promise<PdfSection[]> {
    const logs = await Promise.all(
      [
        firstValueFrom(this.getJourneyLogs(this.journeyService.getNonNullableJourney().journeyID, JourneyLogType.Session)),
        firstValueFrom(this.getJourneyLogs(this.journeyService.getNonNullableJourney().journeyID, JourneyLogType.SaveAndExit)),
        firstValueFrom(this.getJourneyLogs(this.journeyService.getNonNullableJourney().journeyID, JourneyLogType.Published))
      ]);

    return [
      JourneyLogsService.getPdfSection(logs[0] ?? [], "Session"),
      JourneyLogsService.getPdfSection(logs[1] ?? [], "Save and exit"),
      JourneyLogsService.getPdfSection(logs[2] ?? [], "Published", true),
    ];
  }

  async createLogOutLog(): Promise<void> {
    const createDate = moment();
    await firstValueFrom(this.saveJourneyLog([
      this.createJourneyLog(
        JourneyLogType.SaveAndExit,
        this.journeyService.journey ? this.journeyService.journey.journeyID : "Publish",
        `Log out, ${this.adviserService.getLoggedInUserName()}, ${createDate.format('dddd, MMMM Do YYYY, h:mm:ss a Z')}`,
        void null,
        void null,
        createDate.toDate(),
        this.journeyService.journey?.journeyInstanceID,
        this.journeyClientService.getNonNullablePrimaryClientID()
      )]));
  }

  private static getPdfSection(journeyLogs: JourneyLog[], title: string, breakLine = false): PdfSection {
    return {
      pdfSectionType: PdfSectionTypes.List,
      title,
      content: [...journeyLogs.map(x => x.message).filter((x): x is string => Boolean(x))],
      breakLine
    };
  }
}